import "../src/assets/styles/App.scss";
import { Routes, Route } from "react-router-dom";
import { OfferBar, NavBar, Footer } from "./components";
import { useState, useEffect } from "react";
import {
  Main,
  FAQ,
  ContactUs,
  Refund,
  Terms,
  Product,
  Cart,
  TransSucess,
  Collections,
  About,
  ProductsFiltered,
} from "./pages";
import productInfo from "./assets/data/productInfo";
import {
  siteInfo,
  faq,
  refundPolicy,
  termsOfService,
  about,
  contact,
  transactionSuccess,
} from "./assets/data/siteData";

function App() {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cart, setCart] = useState([]);

  const handleProductClick = (product) => {
    setSelectedProduct(product.id);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchProducts = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const filteredProducts = productInfo.filter((product) =>
      product.name.toLowerCase().includes(lowerCaseSearchTerm)
    );

    setFilteredProducts(filteredProducts);
  };

  const pageReset = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const addToCart = (e) => {
    const { id, name, salePrice, media, quantity } = e;
    const image = media[0].item;
    const item = { id, name, salePrice, image, quantity };
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const updatedProducts = cart.map((p) => {
      if (p.id === item.id) {
        return { ...p, quantity: p.quantity + item.quantity };
      }
      return p;
    });

    const isItemAlreadyInCart = cart.some((p) => p.id === item.id);

    if (isItemAlreadyInCart) {
      setCart(updatedProducts);
      localStorage.setItem("cart", JSON.stringify(updatedProducts));
    } else {
      setCart([...cart, item]);
      localStorage.setItem("cart", JSON.stringify([...cart, item]));
    }
  };

  const incrementQuantity = (e) => {
    const updatedProducts = cart.map((product) => {
      if (product.id === e.id) {
        return { ...product, quantity: product.quantity + 1 };
      }

      return product;
    });
    return setCart(
      updatedProducts,
      localStorage.setItem("cart", JSON.stringify(updatedProducts))
    );
  };

  const decrementQuantity = (e) => {
    const updatedProducts = cart.map((product) => {
      if (product.id === e.id) {
        if (product.quantity > 1) {
          return { ...product, quantity: product.quantity - 1 };
        } else {
          return { ...product, quantity: 1 };
        }
      }
      return product;
    });

    return setCart(
      updatedProducts,
      localStorage.setItem("cart", JSON.stringify(updatedProducts))
    );
  };

  const removeItem = (e) => {
    const removedProducts = cart.filter((product) => product.id !== e.id);
    setCart(removedProducts);
    localStorage.setItem("cart", JSON.stringify(removedProducts));
    // );
  };

  useEffect(() => {
    setCart(
      localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : []
    );
  }, []);

  return (
    <div className="App">
      <OfferBar />
      <NavBar
        searchProducts={searchProducts}
        handleInputChange={handleInputChange}
        searchTerm={searchTerm}
        filteredProducts={filteredProducts}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Main
              productInfo={productInfo}
              handleProductClick={handleProductClick}
              addToCart={addToCart}
              pageReset={pageReset}
            />
          }
        />
        <Route
          path="/product/:productId"
          element={
            <Product
              productInfo={productInfo}
              selectedProduct={selectedProduct}
              addToCart={addToCart}
            />
          }
        />
        <Route path="/faq" element={<FAQ faq={faq} />} />
        <Route path="/contact-us" element={<ContactUs contactUs={contact} />} />
        <Route path="/refund" element={<Refund refund={refundPolicy} />} />
        <Route path="/terms" element={<Terms terms={termsOfService} />} />
        <Route
          path="/cart"
          element={
            <Cart
              cart={cart}
              increment={incrementQuantity}
              decrement={decrementQuantity}
              removeItem={removeItem}
            />
          }
        />
        <Route
          // path="/transaction-success/:approved"
          path="/transaction-success"
          element={<TransSucess transactionSuccess={transactionSuccess} />}
        />
        <Route
          path="/featured-collection"
          element={
            <Collections
              productInfo={productInfo}
              handleProductClick={handleProductClick}
              addToCart={addToCart}
            />
          }
        />
        <Route path="/about" element={<About about={about} />} />
        <Route
          path="/products-filtered"
          element={
            <ProductsFiltered
              filteredProducts={filteredProducts}
              handleProductClick={handleProductClick}
              addToCart={addToCart}
            />
          }
        />
      </Routes>
      <Footer handleProductClick={pageReset} siteInfo={siteInfo} />
    </div>
  );
}

export default App;
