function OfferBar() {
  return (
    <>
      <section className="offer-bar center">
        <h1 className="offer-bar__title">50% OFF SALE + FREE SHIPPING</h1>
      </section>
    </>
  );
}

export default OfferBar;
