import { AddToCart } from "../components";

const ProductCart = (props) => {
  const { product } = props;
  return (
    <div
      className="main__card"
      onClick={() => props.handleProductClick(product)}
    >
      <img src={product.media[0].item} alt="product" className="main__img" />
      <h3 className="main__name">{product.name}</h3>
      <div className="main__prices">
        <p className="main__regprice">${product.regPrice}</p>
        <p className="main__saleprice">${product.salePrice}</p>
      </div>
      <AddToCart addToCart={props.addToCart} product={product} />
    </div>
  );
};

export default ProductCart;
