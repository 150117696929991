const siteName = "Pro Gear 180";

const siteInfo = {
  siteName: `${siteName}`,
  mission: `Welcome to ${siteName}, your ultimate destination for unlocking your creative potential! At ${siteName}, we have crafted a haven exclusively for passionate content creators like yourself, who are constantly seeking the finest tools and gear to elevate their content production`,
  email: "support@progear180.com",
};

const faq = [
  {
    q: `How can I contact ${siteName}?`,
    a: `You can email us at ${siteInfo.email} where our customer service team will be happy to help you with whatever you need!`,
  },
  {
    q: "Where do you ship from?",
    a: "We ship from Hong Kong",
  },
  {
    q: "Can I change or cancel my order?",
    a: "As we aim to process orders as fast as possible, you must request any changes/cancellations within 12 hours of ordering. All requests after this time will be denied. Your order can be returned for a full refund after it is received.",
  },
  {
    q: "How do I return an item?",
    a: `Pease contact us at ${siteInfo.email}`,
  },
  {
    q: "What payment methods do you accept?",
    a: "We accept all major credit cards (VISA, Mastercard, AMEX) and PayPal payments.",
  },
  {
    q: "When will my order be processed?",
    a: "All orders are handled and shipped out from our warehouse. Please allow extra time for your order to be processed during holidays and sale seasons. We process orders between Monday and Friday. Orders will be processed within 1-3 business days from the order date and shipped the next day after the processing day. Please note that we don't ship on weekends.",
  },
  {
    q: "How long will it take to receive my order?",
    a: "Due to high demand, orders may take between 2-4 weeks to arrive.",
  },
  {
    q: "What if I don't receive my order?",
    a: "If you don't receive your order within 30 days after shipping, you are eligible for a full refund.",
  },
  {
    q: "Will I be charged with customs and taxes?",
    a: "The prices displayed on our site are tax-free in US Dollars, which means you may be liable to pay for duties and taxes once you receive your order. Import taxes, duties and related customs fees may be charged once your order arrives to its final destination, which are determined by your local customs office. Payment of these charges and taxes are your responsibility and will not be covered by us. We are not responsible for delays caused by the customs department in your country. For further details of charges, please contact your local customs office.",
  },
];

const refundPolicy = [
  {
    title: "Refund Policy",
    text: [
      [
        "We have a 30-day return policy, which means you have 30 days after receiving your item to request a return.",
        "To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.",
        `To start a return, you can contact us at ${siteInfo.email}. Please note that returns will need to be sent to the designated address.`,
        "If your return is accepted, we’ll send you a return shipping label, as well as instructions on how and where to send your package. Items sent back to us without first requesting a return will not be accepted.",
        `You can always contact us for any return question at ${siteInfo.email}`,
      ],
    ],
  },
  {
    title: "Damages and issues",
    text: [
      "Please inspect your order upon reception and contact us immediately if the item is defective, damaged, or if you receive the wrong item, so that we can evaluate the issue and make it right.",
    ],
  },
  {
    title: "Exceptions / non-returnable items",
    text: [
      "Certain types of items cannot be returned, like perishable goods (such as food, flowers, or plants), custom products (such as special orders or personalized items), and personal care goods (such as beauty products). We also do not accept returns for hazardous materials, flammable liquids, or gases. Please get in touch if you have questions or concerns about your specific item.",
      "Unfortunately, we cannot accept returns on sale items or gift cards.",
    ],
  },
  {
    title: "Exchanges",
    text: [
      "The fastest way to ensure you get what you want is to return the item you have, and once the return is accepted, make a separate purchase for the new item.",
    ],
  },
  {
    title: "European Union 14 day cooling off period",
    text: [
      "Notwithstanding the above, if the merchandise is being shipped into the European Union, you have the right to cancel or return your order within 14 days, for any reason and without a justification. As above, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.",
    ],
  },
  {
    title: "Refunds",
    text: [
      "We will notify you once we’ve received and inspected your return, and let you know if the refund was approved or not. If approved, you’ll be automatically refunded on your original payment method within 10 business days. Please remember it can take some time for your bank or credit card company to process and post the refund too.",
      "If more than 15 business days have passed since we’ve approved your return, please contact us at",
    ],
  },
];

const termsOfService = [
  {
    title: "Terms of Service",
    text: [
      "This website is operated by Pro Gear 180. Throughout the site, the terms “we”, “us” and “our” refer to Pro Gear 180. Pro Gear 180 offers this website, including all information, tools and Services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.",
      "By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.",
      "Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any Services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.",
      "Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.",
      "Our store is hosted on Shopify Inc. They provide us with the online e-commerce platform that allows us to sell our products and Services to you.",
    ],
  },
  {
    title: "",
    text: [
      "By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.",
      "You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).",
      "You must not transmit any worms or viruses or any code of a destructive nature.",
      "A breach or violation of any of the Terms will result in an immediate termination of your Services.",
    ],
  },
  {
    title: "General Conditions",
    text: [
      "We reserve the right to refuse Service to anyone for any reason at any time.",
      "You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.",
      "You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the Service is provided, without express written permission by us.",
      "The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.",
    ],
  },

  {
    title: "Accuracy, Completeness and Timeliness of Information",
    text: [
      "We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.",
      "This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.",
    ],
  },

  {
    title: "Modifications to the Service and Prices",
    text: [
      "Prices for our products are subject to change without notice.",
      "We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time.",
      "We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.",
    ],
  },
  {
    title: "Products or Services (if applicable)",
    text: [
      "Certain products or Services may be available exclusively online through the website. These products or Services may have limited quantities and are subject to return or exchange only according to our Refund Policy: [LINK TO REFUND POLICY]",
      "We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.",
      "We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or Services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or Service made on this site is void where prohibited.",
      "We do not warrant that the quality of any products, Services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected.",
    ],
  },
  {
    title: "Accuracy of Billing and Account Information",
    text: [
      "We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e‑mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors.",
      "You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed.",
      "For more details, please review our Refund Policy:",
    ],
  },
  {
    title: "Optional Tools",
    text: [
      "We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.",
      "You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.",
      "Any use by you of the optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).",
      "We may also, in the future, offer new Services and/or features through the website (including the release of new tools and resources). Such new features and/or Services shall also be subject to these Terms of Service.",
    ],
  },
  {
    title: "Third Party Links",
    text: [
      "Certain content, products and Services available via our Service may include materials from third-parties.",
      "Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or Services of third-parties.",
      "We are not liable for any harm or damages related to the purchase or use of goods, Services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.",
    ],
  },
  {
    title: "User Comments, Feedback and Other Submissions",
    text: [
      "If, at our request, you send certain specific submissions (for example contest entries) or without a request from us, you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments.",
      "We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service.",
      "You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e‑mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.",
    ],
  },
  {
    title: "Personal Information",
    text: [
      "Your submission of personal information through the store is governed by our Privacy Policy, which can be viewed here:",
    ],
  },
  {
    title: "Errors, Inaccuracies and Omissions",
    text: [
      "Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order).",
      "We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.",
    ],
  },
  {
    title: "Prohibited Uses",
    text: [
      "In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.",
    ],
  },
  {
    title: "Disclaimer of Warranties: Limitation of Liability",
    text: [
      "We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure or error-free.",
      "We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.",
      "You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.",
      "You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and Services delivered to you through the Service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.",
      "In no case shall Pro Gear 180, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, Service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or any products procured using the Service, or for any other claim related in any way to your use of the Service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.",
    ],
  },
  {
    title: "Indemnification",
    text: [
      "You agree to indemnify, defend, and hold harmless Pro Gear 180 and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, Service providers, subcontractors, suppliers, interns, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.",
    ],
  },
  {
    title: "Severability",
    text: [
      "In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service. Such determination shall not affect the validity and enforceability of any other remaining provisions.",
    ],
  },
  {
    title: "Termination",
    text: [
      "The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.",
      "These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.",
      "If, in our sole judgment, you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we may also terminate this agreement at any time without notice, and you will remain liable for all amounts due up to and including the date of termination, and/or we may deny you access to our Services (or any part thereof).",
    ],
  },
  {
    title: "Entire Agreement",
    text: [
      "The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.",
      "These Terms of Service and any policies or operating rules posted by us on this site or in respect to the Service constitute the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications, and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).",
      "Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.",
    ],
  },
  {
    title: "Governing Law",
    text: [
      "These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Australia.",
    ],
  },
  {
    title: "Changes to Terms of Service",
    text: [
      "You can review the most current version of the Terms of Service at any time on this page.",
      "We reserve the right, at our sole discretion, to update, change, or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.",
    ],
  },
  {
    title: "Contact Information",
    text: [
      `Questions about the Terms of Service should be sent to us at ${siteInfo.email}`,
    ],
  },
];

const about = {
  title: "About Us",
  text: [
    "Welcome to Pro Gear 180, where passion meets technology!",
    "At Pro Gear 180, we are not just another technology retailer. We are a team of dedicated enthusiasts who live and breathe the latest tech trends. Our mission is simple: to provide you with the most cutting-edge and top trending technology that enhances your life and keeps you ahead of the curve.",
    "What drives us? It's the excitement that comes with every new advancement in the world of technology. We genuinely love exploring the possibilities and the endless potential that technology brings. Our team spends countless hours researching, testing, and handpicking the very best products to bring to your doorstep.",
    "Why do we focus on the top trending technology? Because we believe in the power of innovation. We strive to be at the forefront of the industry, offering you the latest gadgets and devices that push the boundaries of what is possible. We want to bring you the future today, allowing you to experience firsthand the wonders of tomorrow's technology.",
    "But it's not just about the products we sell; it's the impact they can have on your life. We are firm believers that technology has the ability to transform the way we live, work, and play. Whether it's a state-of-the-art smartphone that connects you with loved ones around the world or a smart home device that makes your everyday tasks easier, we are here to enhance your digital lifestyle.",
    "What sets us apart from the competition is our unwavering commitment to exceptional customer service. We are not just here to make a sale; we are here to build lasting relationships with our customers. Our knowledgeable team is always ready to answer your questions, offer personalized recommendations, and provide support even after your purchase.",
    "At Pro Gear 180, we are not satisfied with being average. We constantly strive for excellence, which is why we partner with renowned brands and manufacturers who share our passion for quality and innovation. Rest assured that every product you find on our website has undergone rigorous scrutiny to meet our high standards.",
    "Join us on this exhilarating journey into the world of top trending technology. Explore our website, discover the latest innovations, and let us help you elevate your digital experience. Whether you are a tech enthusiast, a professional seeking productivity tools, or someone looking to simplify their life with smart devices, we have something extraordinary for you.",
    "Thank you for choosing Pro Gear 180, where technology and passion converge. We look forward to serving you and being your trusted partner in all things tech.",
    "Embrace the future with us!",
    "The Pro Gear 180 Team",
  ],
};

const contact = {
  title: "Contact Us",
  text: [
    "We value your feedback, questions, and inquiries. Feel free to reach out to us using the contact information below. We are here to assist you and provide the information you need.",
    "Whether you have a suggestion, a query, or simply want to get in touch, we look forward to hearing from you. We strive to respond to all emails as quickly as possible.",
    "If you have any specific questions or concerns regarding our products, services, or any other aspect of our business, please don't hesitate to reach out. We believe in open communication and are committed to ensuring your experience with us is positive and satisfactory.",
    "Please note that while we make every effort to respond to all emails promptly, it may take us a little longer during peak periods. However, rest assured that your message is important to us, and we will get back to you as soon as we can.",
    "Thank you for visiting our website and considering reaching out to us. We look forward to connecting with you and addressing any inquiries or concerns you may have.",
    "Best regards,",
    `${siteName}`,
  ],
};

const transactionSuccess = [
  {
    title: "Transactions Approved!",
    text: "We are thrilled to inform you that your recent purchase has been successfully processed and approved. Thank you for choosing our online store for your shopping needs. We can't wait for you to receive your merchandise!",
  },
  {
    title: "What Happens Next?",
    text: [
      "Estimated Delivery Time: You can expect to receive your order within 2-4 weeks from today's date. We are working diligently to ensure your package arrives promptly and in excellent condition.",
      "Shipment Confirmation: Once your order is dispatched, we will send you a shipment confirmation email with tracking details. You can use the tracking information to stay updated on the progress of your delivery.",
      "Secure Packaging: Your items will be carefully packaged to guarantee their safety during transit. We take great care to ensure that your merchandise arrives in perfect condition, ready for you to enjoy.",
    ],
  },
  {
    title: "Need Help?",
    text: [
      "Should you have any questions or require further assistance regarding your order, please don't hesitate to reach out to our customer support team. We are here to help and provide you with any necessary information.",
    ],
  },
  {
    title: "Thank you for choosing our online store!",
    text: [
      "We appreciate your business and trust in our products. If you have any feedback or suggestions, we would love to hear from you. We hope you enjoy your new purchase and look forward to serving you again in the future.",
    ],
  },
];
export {
  siteInfo,
  faq,
  refundPolicy,
  termsOfService,
  about,
  contact,
  transactionSuccess,
};
