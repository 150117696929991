// import { about } from "../assets/data/siteData.js";

const About = (props) => {
  return (
    <section className="aboutUs">
      <h2 className="aboutUs__title">{props.about.title}</h2>
      {props.about.text.map((paragraph, index) => {
        return (
          <p className="aboutUs-content" key={index}>
            {paragraph}
          </p>
        );
      })}
    </section>
  );
};

export default About;
