function FAQ(props) {
  return (
    <div className="faq">
      <section className="faq-content">
        <h1 className="faq__title">FAQ</h1>
        {props.faq.map((item, key) => {
          return (
            <div key={key}>
              <h2 className="faq__q">{item.q}</h2>
              <p className="faq__a">{item.a}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default FAQ;
