function Refund(props) {
  return (
    <div className="refund">
      {props.refund.map((item, key) => {
        return (
          <section className="refund-content" key={key}>
            <h2 className="refund-title">{item.title}</h2>
            {item.text.map((text, key) => {
              return (
                <p key={key} className="refund-content">
                  {text}
                </p>
              );
            })}
          </section>
        );
      })}
    </div>
  );
}

export default Refund;
