import tripod1 from "../images/products/autoTripod/rotatingTripod01.jpg";
import tripod5 from "../images/products/autoTripod/rotatingTripod05.jpg";
import tripod3 from "../images/products/autoTripod/rotatingTripod03.jpg";
import tripod4 from "../images/products/autoTripod/rotatingTripod04.jpg";
import tripodVideo1 from "../images/products/autoTripod/AutoFaceTracking4.mp4";

// import cameraBulb1 from "../images/products/cameraLightBulb/cameraBulb_01.jpeg";
// import cameraBulb2 from "../images/products/cameraLightBulb/cameraBulb_02.jpeg";
// import cameraBulb3 from "../images/products/cameraLightBulb/cameraBulb_03.jpeg";
// import cameraBulb4 from "../images/products/cameraLightBulb/cameraBulb_04.jpeg";
// import AccueMeasure1 from "../images/products/accueLineLaserMeasure/laserRuler_02.png";
// import AccueMeasure2 from "../images/products/accueLineLaserMeasure/laserRuler_03.png";
// import AccueMeasure3 from "../images/products/accueLineLaserMeasure/laserRuler_05.png";
// import AccueMeasure4 from "../images/products/accueLineLaserMeasure/laserRuler_12.png";
import rignLight1 from "../images/products/ringLight/1.jpg";
import rignLight2 from "../images/products/ringLight/2.jpg";
import rignLight3 from "../images/products/ringLight/3.jpg";
import rignLight4 from "../images/products/ringLight/4.jpg";
import rignLight5 from "../images/products/ringLight/5.jpg";
import rignLight6 from "../images/products/ringLight/6.jpg";
import powerBank from "../images/products/powerBank/1.jpg";
import powerBank2 from "../images/products/powerBank/2.jpg";

const productInfo = [
  {
    id: "b6176830-de8f-4004-944b-5df994e4002a",
    name: "Track Following Tripod",
    regPrice: 99.99,
    salePrice: 49.99,
    collection: "Best Seller",
    media: [
      {
        type: "image",
        item: tripod1,
      },
      {
        type: "video",
        item: tripodVideo1,
      },

      {
        type: "image",
        item: tripod3,
      },
      {
        type: "image",
        item: tripod4,
      },
      {
        type: "image",
        item: tripod5,
      },
    ],
    description: [
      {
        type: "paragraph",
        item: [
          {
            type: "benefit",
            text: "Auto Tracking",
          },
          {
            type: "text",
            text: "Introducing our innovative auto tracking camera mount that will change the way you capture videos and photos forever! Our camera mount adopts dual-core AI computing power that automatically recognizes your face or shape, and tracks your every move with lightning-fast speed. With this portable robot camera, you'll no longer have to worry about adjusting your phone every time you move.",
          },
          {
            type: "benefit",
            text: "No App Required",
          },
          {
            type: "text",
            text: "Unlike other tracking devices, our camera mount doesn't require any apps to function. The built-in camera automatically tracks your movements, so you don't need to download any apps or connect to Bluetooth. This makes it incredibly easy to use, and better protects your privacy.",
          },
          {
            type: "benefit",
            text: "Versatile",
          },
          {
            type: "text",
            text: "Our camera mount is also incredibly versatile, allowing you to use your phone freely while it's mounted. Whether you want to use Tiktok, Facebook, Zoom, YouTube, FaceTime, or any other app, this tracking tripod will always track you fast. Additionally, you can use an iPhone or an Android phone, as there's no limitation to your phone system.",
          },
          {
            type: "benefit",
            text: "Long Battery Life",
          },
          {
            type: "text",
            text: "Our camera mount is battery-powered, with a built-in 2200mAh rechargeable lithium battery that can be charged by USB cord. This allows you to carry it around indoors or outdoors for about 6-8 hours, making it perfect for long shoots or outdoor events.",
          },
          {
            type: "benefit",
            text: "Adjustable",
          },
          {
            type: "text",
            text: "The adjustable 180°clamp rotation and 145°neck can choose horizontal or vertical camera mode, can be adjusted at multiple angles. With a 105°field of view angle of the lens and 42°rotation speed per second, this tracking tripod can track your movement wherever you go, making you the focus all the time.",
          },
          {
            type: "benefit",
            text: "Easy to Use",
          },
          {
            type: "text",
            text: "Our camera mount comes with a standard 1/4 screw thread at the bottom that can be mounted on a tripod and adjust the angle and height. The included phone holder expands 2-4 wide to securely fit any size cell phone. And for those who want to take selfies or make videos at night, you can choose to add a Bluetooth remote and a selfie ring light clip.",
          },
          {
            type: "benefit",
            text: "Overall",
          },
          {
            type: "text",
            text: "Overall, our camera mount is the perfect accessory for anyone who wants to capture high-quality videos and photos without the hassle of constantly adjusting their phone. It's easy to use, versatile, and perfect for indoor or outdoor use.",
          },
        ],
      },
    ],
    social: [
      {
        stars: 4,
        name: "Chalice Ardith",
        title: "Good phone holder that tracks",
        location: "Canada",
        review:
          "Pleased with this Auto tracker, operates as per description. It follows well with normal pace movement and operates quietly. Pushing and holding button to turn on and start tracking takes about 5 sec. same for turn off. It would be nice if the blue on indicator led was a little larger so able to see on or off easier. The phone clamp holds the phone securely. Overall good phone holder with the benefit of tracking motion if needed. I bought a bluetooth Camtix pushbutton to start/stop video or take pictures remotely and these fit my needs.",
      },
      {
        stars: 4,
        name: "Jere Petra",
        title: "Works amazing!",
        location: "Canada",
        review:
          "I got this so I could move around while on video calls and it really works. It follows me wherever I go or when I turn my body. The only drawback is that when I'm sitting it sometimes focuses on the back of my chair as it has a high back. If I am walking around, it follows me everywhere with no issues.",
      },
      {
        stars: 4,
        name: "Tiana Zeke",
        title: "Bien",
        location: "Canada",
        review:
          "Bien, il est trop sensible au mouvement . À part ça les mouvements de rotation son bien . Utiliser pour le basket il m’a offert des Belle sécance",
      },
      {
        stars: 4,
        name: "Wynona Duncan",
        title: "Excellent product",
        location: "Canada",
        review:
          "My wife and my kid likes it. They really enjoyed the 180 rotation. Overall the product is excellent. I will recommend this product to anyone.",
      },
      {
        stars: 5,
        name: "Benson Ollie",
        title: "No app needed",
        location: "Canada",
        review:
          "So much fun using this gadget/accessory/ies. When taking photos and videos i don’t need to use clicker to stop the video and move the tripod. 100% sturdy. Affordable.",
      },
      {
        stars: 5,
        name: "Maddie Genevieve",
        title: "Good Quality",
        location: "Canada",
        review: "Good Deal in Reasonable Price",
      },
    ],
  },
  {
    id: "bcecb046-1da3-4443-98cb-90c7fad44690",
    name: "Fill Ring Light Lamp For Mobile Devices",
    regPrice: 65.99,
    salePrice: 49.99,
    collection: "Best Seller",
    media: [
      {
        type: "image",
        item: rignLight1,
      },
      {
        type: "image",
        item: rignLight2,
      },
      {
        type: "image",
        item: rignLight3,
      },
      {
        type: "image",
        item: rignLight4,
      },
      {
        type: "image",
        item: rignLight5,
      },
      {
        type: "image",
        item: rignLight6,
      },
    ],
    description: [
      {
        type: "paragraph",
        item: [
          {
            type: "text",
            text: "Introducing our Selfie Circular Photo Ring Light: The Ultimate Lighting Solution for Content Creators!",
          },
          {
            type: "text",
            text: "Are you a YouTube, Facebook, or Instagram creator looking to enhance the quality of your content? Look no further! Our Selfie Circular Photo Ring Light is designed specifically for content creators like you, providing you with professional lighting and convenience wherever you go.",
          },
          {
            type: "benefit",
            text: "Table Tripod Stand",
          },
          {
            type: "text",
            text: "This versatile tripod stand offers you the flexibility to place it on the floor or your table, ensuring maximum convenience during your shoots. The stand supports all 1/4 diameter screw phone holders, allowing you to connect your phone separately for flawless selfies.",
          },
          {
            type: "benefit",
            text: "Multi-Functions",
          },
          {
            type: "text",
            text: "With our 2-in-1 design, we combine a phone holder and a selfie ring light into a single tool. This professional setup is perfect for creating live videos, recording with your smartphone or computer, and achieving that perfect lighting. Whether it's make-up tutorials, live streaming, selfie photos, online teaching, or dark scenery vlogging, our Selfie Circular Photo Ring Light has got you covered.",
          },
          {
            type: "benefit",
            text: "Universal Phone Holder",
          },
          {
            type: "text",
            text: "Our product is compatible with almost ALL smartphones, with or without a case. Whether you have an iPhone, Samsung Galaxy, Huawei, Xiaomi, Moto, Sony, Alcatel, LG, HTC, or any other popular smartphone brand, our adjustable phone holder can accommodate your device. Enjoy a 360-degree rotatable and adjustable viewing experience, whether you prefer a horizontal, vertical, or any angle under the sun.No need to worry about power sources! Our ring light can be easily powered by most devices that support a USB port. Connect it to your laptop, PC, power bank, USB charger, AC adapter, or any other compatible device. The easy control on the cord allows you to power on/off or switch to your preferred lighting mode effortlessly.",
          },
          {
            type: "benefit",
            text: "Light Weight",
          },
          {
            type: "text",
            text: "We understand the importance of portability for content creators. Our Selfie Circular Photo Ring Light features a lightweight and compact design, making it easy to disassemble and assemble. Carry it with you wherever you go, and be ready to capture stunning content anytime, anywhere.",
          },
          {
            type: "benefit",
            text: "Professional",
          },
          {
            type: "text",
            text: "Upgrade your content creation game with our Selfie Circular Photo Ring Light! Say goodbye to subpar lighting and hello to professional-quality videos and photos. Order now and take your YouTube, Facebook, and Instagram presence to new heights!",
          },
        ],
      },
    ],
    social: [
      {
        stars: 4,
        name: "Collins Rosemary",
        title: "Great",
        location: "Canada",
        review: "It works",
      },
      {
        stars: 4,
        name: "Vinny Dick",
        title: "As expected, came with extra batteries and a screw driver!",
        location: "Canada",
        review:
          "Can’t complain got everything needed to function + keep functioning",
      },
      {
        stars: 5,
        name: "Alaina Alesia",
        title: "Absolutely perfect",
        location: "Canada",
        review:
          "Great little gadget with built-in tape, measure laser level, etc. does the job",
      },
      {
        stars: 5,
        name: "Miles Arron",
        title: "Works great",
        location: "Canada",
        review:
          "So impressed with this product for the money. Came with batteries and even the screwdriver to change them! Compact yet works like a pro!",
      },
      {
        stars: 5,
        name: "Matt Gainer",
        title: "Versatile product.",
        location: "United Kingdom",
        review:
          "I am a bit lucky in that two of my pastimes can be improved by this laser level. I like working with wood and I'm also a very amateur calligraphist. One of the most important things in both of these hobbies is the ability to produce straight lines vertically, horizontally and at an angle from the vertical in calligraphy. The laser level makes my job a lot easier in all three tasks, especially in the angled indicator line. Certainly much easier than using a small plastic protactor.",
      },
      {
        stars: 4,
        name: "Peggy Hedley",
        title: "Useful",
        location: "Canada",
        review: "A little smaller than I anticipated but useful nonetheless.",
      },
    ],
  },
  {
    id: "6ab2596a-63e2-4ad8-aacc-50f66298b104",
    name: "Power Bank 20000mAh Portable PD 20W Fast Charging External Battery",
    regPrice: 59.99,
    salePrice: 49.99,
    collection: "Best Seller",
    media: [
      {
        type: "image",
        item: powerBank,
      },
      {
        type: "image",
        item: powerBank2,
      },
    ],
    description: [
      {
        type: "title",
        item: [
          {
            type: "benefit",
            text: "Power and Efficiency",
          },
          {
            type: "text",
            text: "As a content creator on Facebook and Instagram, you understand the frustration of running out of battery power while in the midst of capturing that perfect shot or recording engaging videos. With the QOOVI Fast Charging Powerbank, you can say goodbye to low battery anxiety. With its massive 20000mAh capacity, this power bank provides ample power to keep your devices running throughout the day, ensuring you never miss a moment. The Li-polymer battery guarantees efficient and reliable performance, while the double USB output and USB-Type C compatibility allow you to charge multiple devices simultaneously.",
          },
          {
            type: "benefit",
            text: "Quick Charge Technology",
          },
          {
            type: "text",
            text: "Time is of the essence for content creators. You need your devices to charge rapidly so that you can get back to creating and sharing content. The QOOVI Powerbank features two-way Quick Charge Technology, enabling lightning-fast charging for both the power bank itself and your connected devices. With the 22.5W USB A output and the 20W USB C output, you can rapidly recharge your smartphones, tablets, cameras, and other devices, ensuring you're always ready to capture and share your creative endeavors.",
          },
          {
            type: "benefit",
            text: "Digital Display for Real-Time Monitoring",
          },
          {
            type: "text",
            text: "Stay informed about your power bank's battery status with the built-in digital display. This feature allows you to see the remaining battery percentage at a glance, ensuring you're always aware of the power bank's capacity. No more guessing or surprises when you're out and about. The display also indicates the charging status, so you can easily monitor the progress of your devices' charging process.",
          },
          {
            type: "benefit",
            text: "Universal Compatibility",
          },
          {
            type: "text",
            text: "The QOOVI Powerbank is designed to cater to the diverse needs of content creators. Whether you're an iPhone enthusiast, a Samsung user, a Huawei fan, or a Xiaomi devotee, this power bank has got you covered. It supports a wide range of devices, including iPhone 13, Samsung S21, Huawei P50, Xiaomi Mi 11, and many more. With both Type-C and Micro USB input support, you have flexibility in charging the power bank itself using the cable of your choice.",
          },

          {
            type: "text",
            text: "With the QOOVI 20000mAh Fast Charging Powerbank, you can overcome the challenges faced by content creators, such as limited battery life, slow charging speeds, and compatibility issues. Stay powered up, capture incredible content, and share your creative vision with the world effortlessly. Don't let power limitations hold you back - unleash your creativity with the QOOVI Fast Charging Powerbank today!",
          },
        ],
      },
    ],
    social: [
      {
        stars: 5,
        name: "Brian Davies",
        title: "They just right and beautiful",
        location: "Canada",
        review:
          "Love it at nighttime when you have motion detection I live in the suburbs and it help",
      },
      {
        stars: 4,
        name: "Ellis Elea",
        title: "Nice camera for the money",
        location: "United Kingdom",
        review:
          " This is an average camera (no FHD) but the pan/tilt are brilliant! I have the FHD setting on the camera and as you can see in the video and pic, I wouldn't call this FHD (the dog video, the dog is all blurry and you can barely make out the Kawasaki on the motorbike in the other picture with night vison). Day vision is a little better but not crisp as FHD would be.The other picture with the cat is from a different outside motion detection camera that is FHD and there is another picture inside my shed with another FHD motion detection",
      },
      {
        stars: 5,
        name: "Bridget Freeman",
        title: "Good",
        location: "United Kingdom",
        review: "Value for money will buy again",
      },
      {
        stars: 5,
        name: "Maria Bette",
        title: "Notifications, Rotating, and clear video w/ audio",
        location: "United States",
        review:
          "I purchased the Wi-Fi panorama camera for my front driveway and my rear deck. Installation was fast and easy. The activation was done in the house close to the Wi-Fi with my phone next to it. Once both cameras were activated, I took them and installed them outside, one in the front in my driveway. The other one in the back for the rear deck was that turn on the power within minutes it was sending me notifications to my phone. Pictures was clear audio was clear the rotating on the camera followed me everywhere I went and it also snap pictures. I highly recommend it very easy to install. I will say that if you are going to set it up, get yourself a lamp, hook it up next to the Wi-Fi set your phone next to the Wi-Fi and the lamp and proceed to activate the camera, it’ll take less than five minutes for each camera to activate , very simple once activated disconnected from the lamp and go ahead and connect it to wherever it is that you wanted to be once it’s connected in parallel depending on how good your Wi-Fi is if you got good Wi-Fi you have no problem",
      },
      {
        stars: 4,
        name: "Theodora Elvina",
        title: "Good camera quality",
        location: "United States",
        review: "Satisfied with product so far. Good camera quality",
      },
      {
        stars: 5,
        name: "Valérie Zénaïde",
        title: " vraiment impressionnant ca fonctionne super bien",
        location: "Canada",
        review: "facile d’utilisation",
      },
    ],
  },
];

export default productInfo;
