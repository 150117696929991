import { contact, siteInfo } from "../assets/data/siteData.js";

function ContactUs() {
  return (
    <div className="contact">
      <section className="contact-content">
        <h1 className="contact__title">Contact Us</h1>
        <p className="contact__text">
          Please contact us by email at:{" "}
          <a href="mailto:support@progear180.com" id="contact__info">
            {siteInfo.email}
          </a>
        </p>
        {contact.text.map((paragraph, index) => {
          return (
            <p className="contact__text" key={index}>
              {paragraph}
            </p>
          );
        })}
      </section>
    </div>
  );
}

export default ContactUs;
