function Terms(props) {
  return (
    <div className="terms">
      <section className="terms-content">
        {props.terms.map((item, key) => {
          return (
            <div key={key}>
              <h2 className="terms-title">{item.title}</h2>
              {item.text.map((text, key) => {
                return <p key={key}>{text}</p>;
              })}
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default Terms;
