import creditCard_01 from "../assets/images/creditCards/creditCard_01.svg";
import creditCard_02 from "../assets/images/creditCards/creditCard_02.svg";
import creditCard_03 from "../assets/images/creditCards/creditCard_03.svg";
import creditCard_04 from "../assets/images/creditCards/creditCard_04.svg";
import creditCard_05 from "../assets/images/creditCards/creditCard_05.svg";
import creditCard_06 from "../assets/images/creditCards/creditCard_06.svg";
import creditCard_07 from "../assets/images/creditCards/creditCard_07.svg";

import { Link } from "react-router-dom";
import React from "react";

function Footer(props) {
  const footerMenu = [
    {
      title: "FAQs",
      links: "/faq",
    },
    {
      title: "Contact Us",
      links: "/contact-us",
    },
    {
      title: "Refund Policy",
      links: "/refund",
    },

    {
      title: "Terms of Service",
      links: "/terms",
    },
  ];

  const creditCards = [
    creditCard_01,
    creditCard_02,
    creditCard_03,
    creditCard_04,
    creditCard_05,
    creditCard_06,
    creditCard_07,
  ];

  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    const currentRef = domRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section className="footer">
      <section className="footer__info">
        <section
          className={`footer__mission fade-in-left ${
            isVisible ? "is-visible" : ""
          }`}
          ref={domRef}
        >
          <h1 className="footer__title">Our Mission</h1>
          <p className="footer__text">{props.siteInfo.mission}</p>
          <p className="footer__title">- {props.siteInfo.siteName} Team</p>
        </section>
        <section
          className={`footer__menu fade-in-left ${
            isVisible ? "is-visible" : ""
          }`}
          ref={domRef}
        >
          <div className="footer-items">
            {footerMenu.map((item, index) => {
              return (
                <Link
                  to={item.links}
                  className="footer__menu__item"
                  key={index}
                >
                  <h1
                    className="footer__menu__item"
                    key={index}
                    onClick={() => props.handleProductClick()}
                  >
                    {item.title}
                  </h1>
                </Link>
              );
            })}
          </div>
        </section>
      </section>
      <div className="footer__credit-cards centerRow">
        {creditCards.map((item, index) => {
          return (
            <img
              src={item}
              className="footer__credit-card"
              alt="credit-card"
              key={index}
            />
          );
        })}
      </div>
      <div className="footer__copy-right centerColumn">
        &copy; 2023 ProGear 180
      </div>
    </section>
  );
}

export default Footer;
