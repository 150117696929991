import ProductCard from "../components/ProductCard";
import { Link } from "react-router-dom";

const Collections = (props) => {
  const { productInfo, handleProductClick, addToCart } = props;
  return (
    <>
      <section className="main">
        <section className="main__content">
          <h2 className="main__title">COLLECTIONS</h2>

          <section className="main__cards">
            {productInfo.map((product, index) => {
              return (
                <Link to={`/product/${product.id}`} key={index}>
                  <ProductCard
                    product={product}
                    addToCart={addToCart}
                    handleProductClick={handleProductClick}
                  />
                </Link>
              );
            })}
          </section>
        </section>
      </section>
    </>
  );
};

export default Collections;
