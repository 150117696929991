import { useParams } from "react-router-dom";
import { AddQuantityBtn, CTAButton } from "../components";
import { useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";

function Product(props) {
  const productId = useParams();
  const [quantity, setQuantity] = useState(1);

  const increment = (e) => {
    setQuantity(quantity + 1);
  };
  const decrement = (e) => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else if (quantity === 1) {
      setQuantity(1);
    }
  };

  const product = props.productInfo.find(
    (element) => element.id.toString() === productId.productId
  );

  const selectedProduct = { ...product, quantity: quantity };
  console.log(selectedProduct);

  return (
    <section className="product">
      <div className="product__images">
        {product.media.map((item, index) => {
          return item.type === "video" ? (
            <video
              src={item.item}
              controls
              className="product__video fadeInLeft"
              key={index}
            />
          ) : (
            <img
              src={item.item}
              alt="product"
              className="product__img fadeInLeft"
              key={index}
            />
          );
        })}
      </div>

      <section className="product__info fadeInLeft">
        <h2 className="product__name">{product.name}</h2>
        <div className="product__prices">
          <p className="product__regprice">${product.regPrice}</p>
          <p className="product__saleprice">${product.salePrice}</p>
        </div>
        <article className="product__shippingDisclaimer">
          <p className="underline">Shipping </p>calculated at checkout
        </article>
        <h4 className="product__quantityTitle">Quantity</h4>
        <AddQuantityBtn
          quantity={quantity}
          increment={increment}
          decrement={decrement}
        />
        <Link to={"/cart"} onClick={() => props.addToCart(selectedProduct)}>
          <CTAButton text={"Add To Cart"} />
        </Link>
        <ul className="product__description">
          {product.description[0].item.map((item, index) => {
            const text =
              item.type === "benefit" ? (
                <span className="product__descriptionTxt--benefit">
                  {item.text}
                </span>
              ) : (
                <span className="product__descriptionTxt">{item.text}</span>
              );

            return (
              <li className="product__descriptionTxt" key={index}>
                {text}
              </li>
            );
          })}
        </ul>
        <section className="product__reviews">
          <h2 className="product__descriptionTxt--benefit">Customer Reviews</h2>
          {product.social.map((item, index) => {
            return (
              <section className="product__social" key={index}>
                <span className="product__social__rate-name">
                  <h3 className="product__social-name">{item.name}</h3>
                  {"from"}
                  <h3 className="product__social-location">{item.location}</h3>
                </span>

                <span className="product__social__rate-name">
                  <ReactStars value={item.stars} />{" "}
                  <h3 className="product__social-title">{item.title}</h3>
                </span>

                <span className="product__social-locationTxt">
                  {item.review}
                </span>
              </section>
            );
          })}
        </section>
      </section>
    </section>
  );
}

export default Product;
